'use client'

import { useEffect } from "react"
import { usePathname } from "next/navigation"
import mixpanel from "mixpanel-browser"

export default function Mixpanel() {
  const pathname = usePathname()
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL ?? "")

  useEffect(() => {
    mixpanel.track("page_view")
  }, [pathname])
  return <></>
}
